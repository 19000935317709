.danger {
    background-color: #FFD9D9;
}

.warning {
    background-color: #FFEFE4
}

.success {
    background-color: #E0FFE7;
}

.danger, .warning, .success {
    padding: 15px 20px;
    border-radius: 10px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    gap: 3px
}

.title {
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
}

.iconSuccess, .iconDanger, .iconDanger {
    font-size: 16px;
}

.iconDanger {
    color: var(--red)
}

.iconWarning {
    color: var(--orange)
}

.iconSuccess {
    color: var(--green)
}
