.container {
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.question {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .question {
        font-size: 18px;
        padding: 0 10px;
    }
}

.radioContainer {
    height: 77px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 800px) {
    .radioContainer {
        margin-bottom: 0;
    }
}

.answer {
    text-align: center;
    font-size: 14px;
    height: 28px
}

@media (max-width: 800px) {
    .answer {
        font-size: 10px;
    }
}

.radio {
    accent-color: var(--green);
}

