.leftPart {
    flex: 1;
    height: 100vh;
    background-color: var(--blue);
    background-image: url("../assets/boat.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@media (max-width: 1020px) {
    .leftPart {
        display: none;
    }
}

.rightPart {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 1020px) {
    .rightPart {
        padding: 20px
    }
}

.button {
    background-color: var(--green);
    border: none;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    font-size: 1rem;
    font-family: "Caviar Dreams", sans-serif;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.button:hover {
    color: white;
}

.disabledButton {
    background-color: var(--gray);
    border: none;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    font-size: 1rem;
    font-family: "Caviar Dreams", sans-serif;
    cursor: pointer;
}

.buttonRound {
    background-color: var(--green);
    border: none;
    color: white;
    border-radius: 50px;
    padding: 10px 30px;
    font-weight: bold;
    font-size: 18px;
    font-family: "Caviar Dreams", sans-serif;
    cursor: pointer;
    text-decoration: none;
}


.disabledButtonRound {
    background-color: var(--gray);
    border: none;
    color: white;
    border-radius: 50px;
    padding: 10px 30px;
    font-weight: bold;
    font-size: 18px;
    font-family: "Caviar Dreams", sans-serif;
    cursor: pointer;
}

.button:hover, .buttonRound:hover {
    background-color: var(--green-hover);
    color: white
}

.inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0;
}

@media (max-width: 768px) {

    .inputContainer{
        width: 100%;
    }
}

.label {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
}

.inputArea {
    outline: none;
    border-color: #E5E5E5;
    border-width: 1px;
    border-radius: 7px;
    border-style: solid;
    font-size: 16px;
    font-family: "Caviar Dreams", sans-serif;
    display: flex;
    align-items: center;
}

.inputInArea {
    outline: none;
    border: none;
    border-radius: 7px;
    font-size: 16px;
    padding: 10px;
    font-family: "Caviar Dreams", sans-serif;
    flex: 1;
    background-color: white;
}

.iconInput {
    color: var(--green);
    padding: 10px
}

.input {
    outline: none;
    border-color: #E5E5E5;
    border-width: 1px;
    border-radius: 7px;
    border-style: solid;
    font-size: 16px;
    padding: 10px;
    font-family: "Caviar Dreams", sans-serif;
}

.radioContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
}


.mainContainer {
    width: min(1200px, 100% - 40px);
    min-height: calc(100vh - 220px);
    margin: 80px auto 0;
    padding: 20px 0 80px;
}

