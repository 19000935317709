:root {
    --green: #029E42;
    --blue: #0E1E40;
    --green-hover: #028D3B;
    --red: #DB2828;
    --orange: #F2711C;
    --yellow: #FBBD08;
    --gray: #CCCCCC;
    --light-blue: #3B82F6
}

@font-face {
    font-family: 'Rustico';
    src: local('Rustico'),
    url('./fonts/Rustico/Rustico-V2-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: "Caviar Dreams", sans-serif;
    color: var(--blue);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

h1 {
    font-family: Rustico, sans-serif;
    font-size: 44px;
    letter-spacing: 7px;
    text-transform: uppercase;
    margin: 5px
}

h2 {
    font-size: 1.5rem;
    margin: 5px
}

a {
    color: var(--green);
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

a:hover {
    color: var(--green-hover)
}

hr {
    border-top: #AAAAAA solid 1px;
    margin: 20px 0;
}


