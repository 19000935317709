.mainContainer {
    min-height: calc(100vh - 220px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.content {
    display: flex;
    gap: 30px;
    flex-direction: column;
    font-weight: 600;
}

.question {
    font-size: 30px;
    text-align: center;
}

.regateName {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

@media (max-width: 768px) {
    .regateName {
        flex-direction: column;
        gap: 0;
    }
}

.answer {
    font-size: 1.5rem;
}

.infoDiv {
    border: 1px solid #AAAAAA;
    border-radius: 10px;
    padding: 20px;
    font-weight: 300;
}

.infoDiv b {
    font-weight: 600;
}

.infoDiv a {
    text-decoration: underline;
    color: inherit;
    font-weight: 300;
}

.infoDiv a:hover {
    text-decoration: none;
    font-weight: 400;
}

.inputRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 30px;
}

@media (max-width: 768px) {
    .inputRow {
        flex-direction: column;
        gap: 0;
    }
}


.divider {
    text-transform: lowercase;
    font-weight: 300;
    color: #AAAAAA;
    font-size: 1rem;
    text-align: center;
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 2;
    width: auto;
}


@media (min-width: 768px) {
    .divider {
        text-transform: lowercase;
        font-weight: 300;
        color: #AAAAAA;
        font-size: 1rem;
        text-align: center;
        height: 50%;
        transform: translateX(-50%);
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0;
        padding: 0;
        z-index: 2;
        width: auto;
    }

    .divider::before, .divider::after {
        position: absolute;
        left: 50%;
        content: '';
        z-index: 3;
        border-left: 1px solid #AAAAAA;
        border-right: 1px solid #AAAAAA;
        width: 0;
        height: calc(100% - 1.5rem);
    }

    .divider::before {
        top: calc(1rem - 100%);
    }

    .divider::after {
        top: auto;
        bottom: 0;
    }
}


.choicePart {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-weight: 400;
    padding: 30px;
    gap: 5px;
}

@media (max-width: 768px) {
    .choicePart {
        padding: 30px 10px
    }
}


.clickable:hover {
    cursor: pointer;
}

.price {
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    padding: 3px 15px;
    /*background-color: #AAAAAA;*/
    background-color: var(--green);
    border-radius: 50px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

@media (max-width: 768px) {
    .row {
        flex-direction: column;
        gap: 0;
    }
}

.complet {
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    padding: 3px 15px;
    background-color: #555;
    border-radius: 50px;
    text-decoration: unset;
}

.speech {
    display: flex;
    margin-top: 20px
}

@media (max-width: 768px) {
    .speech {
        flex-direction: column;
        gap: 10px;
    }
}
