.container {
    display: flex;
}

.title {
    margin-bottom: 0;
    text-align: center;
    font-family: "Rustico - V2", sans-serif;
}

.subtitle {
    margin-top: 0;
    margin-bottom: 40px;
    font-weight: normal;
    text-align: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 500px;
}

.login {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;
    font-size: 14px;
}
