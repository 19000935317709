.card {
    background: #FAFAFA;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.25);
    /*flex: 1;*/
    display: flex;
    flex-direction: column;
}


.titleContainer {
    background: #0E1E40;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    padding: 10px;
}

.contentContainer {
    padding: 20px;
    font-size: 0.95rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.infosContainer {
    display: flex;
    flex-direction: column;
    flex: 3
}

.lineContainer {
    display: flex;
    margin: 4px 0;
    gap: 15px
}

.icon {
    display: flex;
    justify-content: center;
    width: 25px;
}

.text {
    flex: 7
}

.remainingPlace {
    font-size: 12px;
}

.descriptionTitle {
    font-weight: bold;
    margin-top: 10px;
    padding-left: 10px;
}

.descriptionText {
    margin-top: 5px;
    padding: 0 10px;
    font-size: 14px;
}

.buttonContainer {
    margin-top: 15px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex: 1;
}

.price {
    font-weight: bold;
    margin: 10px 0;
    font-size: 1.1rem;
    flex: 1
}
