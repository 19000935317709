.container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

@media screen and (max-width: 750px) {
    .container {
        grid-template-columns: 1fr;
    }
}
@media screen and (min-width: 1200px) {
    .container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
    }
}
