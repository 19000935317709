.infosCardRed, .infosCardOrange, .infosCardYellow {
    flex: 1;
    background-color: #FAFAFA;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    text-align: center;
    color: white;
    font-weight: bold;
    aspect-ratio: 13/9;
}

@media screen and (max-width:800px) {
    .infosCardRed, .infosCardOrange, .infosCardYellow {
        padding: 0;
    }
}

.infosCardRed {
    background-image: url("../../assets/formes/waveRed.svg");
}

.infosCardOrange {
    background-image: url("../../assets/formes/waveOrange.svg");
}

.infosCardYellow {
    background-image: url("../../assets/formes/waveYellow.svg");
}

.category {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blue);
    font-size: 18px;
}

.degree {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleRed, .titleOrange, .titleYellow {
    font-size: 20px;
    font-weight: bold;
}

.titleRed, .subtitleRed {
    color: var(--red);
}

.titleOrange, .subtitleOrange {
    color: var(--orange);
}

.titleYellow, .subtitleYellow {
    color: var(--yellow);
}

.subtitleRed, .subtitleOrange, .subtitleYellow {
    font-size: 16px;
    font-weight: bolder;
}

