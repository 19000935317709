.line {
    display: flex;
    gap: 30px;
    flex-direction: row
}

.eventsContainer {
    display: flex;
    gap: 30px;
    flex-direction: column
}

@media (max-width: 1200px) {
    .line, .eventsContainer{
        gap: 20px;
    }
}

.container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

@media screen and (max-width: 750px) {
    .container {
        grid-template-columns: 1fr;
    }
}
@media screen and (min-width: 1200px) {
    .container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
    }
}

.card {
    background: #FAFAFA;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.25);
    margin: 30px 0;
}

.titleContainer {
    background: #0E1E40;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1.1rem;
    padding: 10px;
}

.contentContainer {
    padding: 20px;
    flex: 2
}

.title {
    font-weight: bold;
    margin: 5px 0 15px;
    font-size: 1.2rem;
}

.infosContainer {
    display: flex;
    flex-direction: column;
}

.lineContainer {
    display: flex;
    margin: 4px 0;
    gap: 15px
}

.icon {
    display: flex;
    justify-content: center;
    width: 25px;
}

.text {
    flex: 7
}

.remainingPlace {
    font-size: 12px;
}

.descriptionTitle {
    font-weight: bold;
    margin-top: 10px;
    padding-left: 10px;
}

.descriptionText {
    margin-top: 5px;
    padding: 0 10px;
    font-size: 14px;
}

.buttonContainer {
    margin-top: 15px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
}

.price {
    font-weight: bold;
    font-size: 1.2rem;
    text-align: right;
}

.img {
    flex: 1;
    object-fit: cover;
    width: 33%;
    aspect-ratio: 4/3
}

@media (max-width: 900px) {
    .img {
        display: none;
    }
}

