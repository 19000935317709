.container {
    display: flex;
}

.title {
    margin-bottom: 0;
    text-align: center;
}

.subtitle {
    margin-top: 0;
    margin-bottom: 40px;
    font-weight: normal;
    text-align: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.rememberAndForgetContainer {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
    margin: 10px 0
}

.remember {
    display: flex;
    gap: 5px;
    align-items: center;
}

.register {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;
    font-size: 20px;
}
