.card {
    background: #FAFAFA;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.25);
    /*flex: 1;*/
}


.titleContainer {
    background: #0E1E40;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    padding: 10px;
}

.contentContainer {
    padding: 20px;
    font-size: 0.95rem;
}

.infosContainer {
    display: flex;
    flex-direction: column
}

.lineContainer {
    display: flex;
    margin: 4px 0;
    gap: 15px
}

.icon {
    display: flex;
    justify-content: center;
    width: 25px;
}

.text {
    flex: 7
}

.remainingPlace {
    font-size: 12px;
}

.descriptionTitle {
    font-weight: bold;
    margin-top: 10px;
    padding-left: 10px;
}

.descriptionText {
    margin-top: 5px;
    padding: 0 10px;
    font-size: 14px;
}

.buttonContainer {
    margin-top: 15px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
}

.price {
    font-weight: bold;
    margin: 10px 0;
    font-size: 1.1rem;
}

.labelGreen, .labelOrange, .labelRed, .labelBlue {
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    border: 1px solid;
    flex: 1;
}

.labelGreen {
    background-color: #E0FFE7;
    border-color: var(--green);
}

.labelOrange {
    background-color: #FFEFE4;
    border-color: var(--orange);
}

.labelRed {
    background-color: #FFD9D9;
    border-color: var(--red);
}
.labelBlue {
    background-color: #E0F4FF;
    border-color: var(--light-blue);
}

