.flash {
    z-index: 1000;
    position: fixed;
    text-align: center;
    right: 1%;
    width: 33%;
}

@media (max-width: 1200px) {
    .flash {
        width: 50%;
    }
}

@media (max-width: 750px) {
    .flash {
        width: 90%;
        margin: 0 auto;
    }
}

.bulle {
    margin: 30px 20px;
    /*border: 0.1rem solid;*/
    background: #f3f6f8;
    font-size: 90%;
    color: black;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 90px auto;
}

.bulle > .iconBulle {
    display: flex;
    align-items: center;
    -webkit-clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    padding: 15px 25px 15px 20px;
    border-radius: 0.2rem 0 0 0.2rem;
    font-size: 200%;
    color: white;
    width: 30px;
    text-align: center;
}

.bulle > .iconText {
    padding: 15px;
    display: flex;
    align-items: center;
}
