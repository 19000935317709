.empty{
    position: absolute;
    right: 0;
    left: 0;
    bottom: 40px;
}

.div_100 {
    min-height: calc(100vh - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.div_100vh {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@keyframes animate {
    0% {height: 22vh;}
    50% {height: 25vh;}
    100% {height: 22vh;}
}

.logo {
    animation-name: animate;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
}

.error_logo {
    height: 30vh;
    aspect-ratio: auto;
    margin-top: 5vh;
}