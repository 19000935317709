.infosContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1
}

.contentContainer {
    padding: 20px;
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.buttonContainer {
    margin-top: 30px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventContainer {
    display: flex;
    flex-direction: column;
}

.lineContainer {
    display: flex;
    gap: 15px
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
}

.text {
    flex: 7
}

.persoContainer {
    flex: 1
}

.labelGreen, .labelOrange, .labelRed, .labelBlue {
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.labelGreen {
    background-color: #E0FFE7;
    border-color: var(--green);
}

.labelOrange {
    background-color: #FFEFE4;
    border-color: var(--orange);
}

.labelRed {
    background-color: #FFD9D9;
    border-color: var(--red);
}
.labelBlue {
    background-color: #E0F4FF;
    border-color: var(--light-blue);
}

.card {
    background: #FAFAFA;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.25);
    flex: 1;
    padding: 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loading {
    border-radius: 100px;
    border-left: solid 2px var(--blue);
    aspect-ratio: 1;
    height: 100%;
    width: 100%;
    animation-name: rotation;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
    position: relative;
}

@keyframes rotation {
    0% {transform: rotate(0);}
    10% {transform: rotate(36deg);}
    20% {transform: rotate(72deg);}
    30% {transform: rotate(108deg);}
    40% {transform: rotate(144deg);}
    50% {transform: rotate(180deg);}
    60% {transform: rotate(216deg);}
    70% {transform: rotate(252deg);}
    80% {transform: rotate(288deg);}
    90% {transform: rotate(324deg);}
    100% {transform: rotate(360deg);}
}

.overLoading{
    border-radius: 100px;
    border: solid 1px #888;
    aspect-ratio: 1;
    height: 30px;
    width: 30px;
    margin: 10px;
}
