.nav {
    height: 80px;
    width: 100%;
    background-color: var(--blue);
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100;
}

.navLinkContainer {
    display: flex;
    flex: 2;
    justify-content: space-evenly;
}

.navLink {
    font-family: Rustico, sans-serif;
    color: white;
    text-decoration: none;
    font-weight: normal;
    font-size: 1.1rem;
}

.buttonContainer {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    padding-right: 50px;
}

.buttonText {
    font-family: Rustico, sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1rem;
}

.overlayNavbar {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    height: calc(100vh - 80px);
    width: 100vw;
    top: 80px;
    backdrop-filter: blur(5px);
    z-index: 1000;
}

@media (max-width: 1080px) {
    .navLinkContainer{
        flex-direction: column;
        height: 60%;
    }

    .navLink{
        text-align: center;
        font-size: 1.5rem;
    }

    .buttonContainerMobile {
        flex: 1;
        justify-content: center;
        display: flex;
    }

    .buttonText {
        font-size: 1.3rem;
    }
}

.dropdownMenuConnected {
    z-index: 1000;
    background: white;
    position: fixed;
    display: flex;
    flex-direction: column;
    text-align: right;
    box-shadow: 0 20px 30px 0 rgb(0 0 0 / 10%);
    right: 0;
    border-top: 1px solid #ddd;
}

.dropdownMenuItem {
    margin: 15px 0 0 0;
    border-style: solid;
    border-color: rgba(132, 132, 132, 0.15);
    border-width: 0;
    border-bottom-width: 1px;
    padding: 0 60px 15px;
}

.dropdownLink {
    color: #000000;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
}
