.mainContainer {
    min-height: calc(100vh - 220px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.buttonContainer {
    margin-top: 30px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentContainer {
    padding: 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.area {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center
}

@media (max-width: 1020px) {
    .area {
        flex-direction: column;
    }
}

.lineContainer {
    display: flex;
    gap: 15px;
    align-items: flex-start;
    justify-content: flex-start;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
}

.text {
    flex: 7
}

.normal {
    font-weight: normal;
}

.content {
    display: flex;
    gap: 30px;
    flex-direction: column;
}

.price {
    font-weight: bold;
    font-size: 1.2rem;
    margin: 5px 0;
}


.don {
    display: flex;
    flex-direction: row
}

@media (max-width: 768px) {
    .don {
        flex-direction: column;
    }
}
