.contentContainer {
    padding: 20px;
    flex: 1
}

.title {
    font-weight: bold;
    margin: 5px 0 15px;
    font-size: 1.2rem;
}

.infosContainer {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.lineContainer {
    display: flex;
    gap: 15px
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
}

.text {
    flex: 7
}

.remainingPlace {
    font-size: 12px;
    display: inline;
    margin-left: 8px;
}

.descriptionTitle {
    font-weight: bold;
    margin-top: 10px;
    padding-left: 10px;
}

.descriptionText {
    margin-top: 5px;
    padding: 0 10px;
    font-size: 14px;
}

.buttonContainer {
    margin-top: 15px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
}

.price {
    font-weight: bold;
    font-size: 1.2rem;
    margin: 5px 0;
}

.img {
    flex: 1;
    object-fit: cover;
    width: 33%;
    aspect-ratio: 4/3
}

@media (max-width: 900px) {
    .img {
        display: none;
    }
}
