.infosCardContainer {
    display: flex;
    gap: 20px;
}

@media screen and (max-width: 1000px) {
    .infosCardContainer {
        flex-direction: column;
    }
}

.infosCard {
    flex: 1;
    background-color: #FAFAFA;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    background-image: url("../../assets/formes/wave.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    text-align: center;
    color: white;
    font-weight: bold;
}
